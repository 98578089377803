<template>
  <el-dialog
    :title="partsType.id ? '编辑' : (pageType ? '新建一级' : '新建')"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="650px"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="100px"
        :model="partsType"
        :rules="ruleValidate"
        style="padding-right: 20px">
        <el-form-item :label="$t('partsType.no')" prop="no">
          <el-input v-model="partsType.no"></el-input>
        </el-form-item>
        <el-form-item :label="$t('partsType.partsTypeSn')">
          <el-input
            v-model.Number="partsType.partsTypeSn"
            type="number"
            min="-2147483648"
            max="2147483647"
            :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('partsType.name')" prop="name">
          <el-input v-model="partsType.name" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('partsType.desc')">
          <el-input v-model="partsType.desc" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        inNetState: 1,
        pageType: "",
        partsType: {
          id: 0,
          parentID: 0,
          no: "",
          name: "",
          partsTypeSn: 0,
          desc: "",
          isDeleted: 0,
        },
        ruleValidate: {
          no: [
            {required: true, message: this.$t("parts.tip.no"), trigger: "change"},
          ],
          name: [
            {required: true, message: this.$t("parts.tip.name"), trigger: "change"},
          ],
        },
      };
    },
    computed: {},
    methods: {
      open(parentId, id, name, pageType, no, partsTypeSn, desc) {
        console.log(partsTypeSn);
        this.partsType.id = id;
        if (parentId) {
          this.partsType.parentID = parentId;
        } else {
          this.partsType.parentID = 0;
        }
        this.partsType.name = name;
        this.partsType.no = no;
        this.partsType.partsTypeSn = partsTypeSn;
        this.partsType.desc = desc;
        this.pageType = pageType;
        this.dialogVisible = true;
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.contentLoading = false;
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
        this.$refs.formValidate.resetFields();
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            let partsTypeVO;
            if (parseInt(this.partsType.partsTypeSn) > 2147483647) {
              return this.$message.error("数字不能大于2147483647");
            }
            if (parseInt(this.partsType.partsTypeSn) < -2147483648) {
              return this.$message.error("数字不能小于-2147483648");
            }
            if (this.partsType.id) {
              console.log("编辑");
              partsTypeVO = {
                id: this.partsType.id,
                name: this.partsType.name,
                partsTypeSn: parseInt(this.partsType.partsTypeSn),
                parentID: this.partsType.parentID,
                no: this.partsType.no,
                desc: this.partsType.desc.trim(),
                isDeleted: 0,
              };
            } else {
              console.log("新增下一级");
              partsTypeVO = {
                parentID: this.partsType.parentID,
                name: this.partsType.name,
                partsTypeSn: parseInt(this.partsType.partsTypeSn),
                no: this.partsType.no,
                desc: this.partsType.desc ? this.partsType.desc.trim() : "",
                isDeleted: 0,
              };
            }
            console.log(partsTypeVO);
            this.$api.save("partsTypes", partsTypeVO).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", partsTypeVO.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
